<template>
  <div>
    <Header v-if="!whithinPopUp">
      <h1>Register</h1>
    </Header>
    <div v-if="whithinPopUp" class="margin-top-70"></div>

    <!-- Page Content
    ==================================================-->
    <div class="container">
      <div class="row">
        <div :class="whithinPopUp ? 'offset-xl-1 col-xl-10' : 'col-xl-5 offset-xl-3'">
          <div class="login-register-page">
            <!-- <Notification :message="error" v-if="error" /> -->

            <!-- Welcome Text -->
            <div class="welcome-text">
              <h3 style="font-size: 26px">Let's create your account!</h3>
              <span>
                Already have an account?
                <a @click.prevent="redirectToLogin">Log In!</a>

              </span>
            </div>

            <!-- Account Type -->
            <div class="account-type">
              <div>
                <input type="radio" name="account-type-radio" id="freelancer-radio" :value="1" v-model="localType"
                  class="account-type-radio" checked />
                <label for="freelancer-radio" class="ripple-effect-dark"><i
                    class="icon-material-outline-account-circle"></i> Job Seeker</label>
              </div>

              <div>
                <input type="radio" name="account-type-radio" id="employer-radio" :value="2" v-model="localType"
                  class="account-type-radio" />
                <label for="employer-radio" class="ripple-effect-dark"><i
                    class="icon-material-outline-business-center"></i> Employer</label>
              </div>
            </div>

            <!-- Form -->
            <form method="post" @submit.prevent="register" id="register-account-form">
              <InputWithIcon>
                <i>
                  <AccountOutline :size="20" class="icon"></AccountOutline>
                </i>
                <input autocomplete="chrome-off" type="text" class="input-text with-border" name="name" id="name"
                  v-model="$v.name.$model" placeholder="Your name" />
              </InputWithIcon>
              <p class="text-danger" v-if="$v.name.$dirty && !$v.name.required">
                Name is required.
              </p>
              <p class="text-danger" v-if="$v.name.$dirty && !$v.name.onlyCharacters">
                Name should only contain characters.
              </p>
              <InputWithIcon>
                <i>
                  <EmailOutline :size="18" class="icon"></EmailOutline>
                </i>
                <input autocomplete="off" type="text" class="input-text with-border" name="email" id="email"
                  v-model.trim="$v.email.$model" placeholder="Email Address" />
              </InputWithIcon>
              <p class="text-danger" v-if="$v.email.$dirty && !$v.email.required">
                Email is required.
              </p>
              <p class="text-danger" v-if="$v.email.$dirty && !$v.email.email">
                Please enter a valid email.
              </p>

              <InputWithIcon title="Should be at least 8 characters long" data-tippy-placement="bottom">
                <i>
                  <LockOutline :size="18" class="icon"></LockOutline>
                </i>
                <input autocomplete="chrome" v-model.trim="$v.password.$model" class="input-text with-border"
                  type="password" name="password" id="password" :secureLength="8" placeholder="Password" />
              </InputWithIcon>

              <p class="text-danger" v-if="$v.password.$dirty && !$v.password.required">
                Password is required.
              </p>
              <p class="text-danger" v-if="$v.password.$dirty && !$v.password.strongPassword">
                Password must be 8+ characters with at least one letter
              </p>

              <InputWithIcon>
                <i>
                  <LockOutline :size="18" class="icon"></LockOutline>
                </i>
                <input autocomplete="off" type="password" class="input-text with-border" name="confirmPassword"
                  id="confirmPassword" v-model.trim="$v.confirmPassword.$model" placeholder="Repeat Password" />
              </InputWithIcon>
              <p class="text-danger" v-if="$v.confirmPassword.$dirty && !$v.password.required">
                Password is required.
              </p>
              <p class="text-danger" v-if="$v.confirmPassword.$dirty && !$v.confirmPassword.sameAs">
                Passwords must be identical
              </p>

              <div class="checkbox">
                <input type="checkbox" id="chekcbox1" checked="">
                <label for="chekcbox1"><span class="checkbox-icon"></span>Check here to receive promotional
                  emails</label>
              </div>
            </form>

            <!-- Button -->
            <button class="button full-width button-sliding-icon ripple-effect margin-top-10" type="submit"
              form="register-account-form">
              Register
              <i>
                <ArrowRight :size="18" class="icon"></ArrowRight>
              </i>
            </button>

            <!-- Social Login -->
            <!-- <div class="social-login-separator">
              <span>or</span>
            </div>
            <div class="social-login-buttons">
              <button class="facebook-login ripple-effect">
                <i class="icon-brand-facebook-f"></i> Register via Facebook
              </button>
              <button class="google-login ripple-effect">
                <i class="icon-brand-google-plus-g"></i> Register via Google+
              </button>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <!-- Spacer -->
    <div class="margin-top-70"></div>
    <!-- Spacer / End-->
  </div>
</template>

<script>
import Password from "vue-password-strength-meter";
import { required, email, minLength, sameAs, integer } from "vuelidate/lib/validators";
import Header from '~/components/base/Header.vue';
import InputWithIcon from '~/components/base/InputWithIcon.vue';

// Icons
import AccountOutline from 'icons/AccountOutline.vue';
import EmailOutline from 'icons/EmailOutline.vue';
import LockOutline from 'icons/LockOutline.vue';
import ArrowRight from 'icons/ArrowRight.vue';

export default {
  components: {
    Password,
    Header,
    InputWithIcon,
    ArrowRight,
    EmailOutline,
    LockOutline,
    AccountOutline
  },
  data() {
    return {
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
      socialLogin: false,
      //  type: 0,
      localType: this.type,
      error: null,
    };
  },
  props: {
    whithinPopUp: {
      type: Boolean,
      default: false
    },
    type: {
      type: Number,
      default: 1
    },
  },
  validations: {
    name: {
      required,
      onlyCharacters(value) {
        const regex = /^[A-Za-z\s]+$/;
        return regex.test(value);
      },
    },
    email: { required, email },
    password: {
      required,
      strongPassword(password) {
        return (
          /[a-z]/.test(password) && // checks for a-z
          /[0-9]/.test(password) && // checks for 0-9
          password.length >= 8
        );
      },
    },
    confirmPassword: {
      required,
      sameAs: sameAs("password"),
    },
  },

  methods: {
    redirectToLogin: function () {
      this.$emit("switchComponent", "LogIn");
    },
    register: function () {
      this.$v.$touch();
      if (this.$v.$error) {
        // console.log($v);
        return;
      }

      this.doRegister();
    },

    async doRegister() {
      var self = this;

      try {
        $nuxt.$root.$loading.start();
        var user = {
          email: this.email,
          password: this.password,
          name: this.name,
          type: this.localType,
        };
        this.$axios
          .post("/auth/user/register", {
            user: user,
          })
          .then((response) => {
            $nuxt.$root.$loading.finish();
            if (response.data.userId) {
              self.$toasted.global.userexist();
              setTimeout(x => {
                this.redirectToLogin();
              }, 3000)

            } else {
              // self.welcomeUser();
              self.$emit("emailNewUser", self);
              this.loginUser(user);
              self.$toasted.global.registersuccessful();
            }
            //this.$router.push("/");
          }).catch((ex) => {
            $nuxt.$root.$loading.finish();
            self.$handleExceptionWithSentry(ex);
          });
      } catch (e) {
        self.$sentry.captureException(e);
        self.error = e.response
          ? e.response.data.message
          : "Error while register";
      }
    },

    loginUser({ email, password }) {
      var self = this;
      $nuxt.$root.$loading.start();
      this.$auth.loginWith("local", {
        data: {
          user:
          {
            email: email,
            password: password,
            name: '',
            socialLogin: false
          }
        },
      })
        .then(x => {
          $nuxt.$root.$loading.finish();
          self.$router.push({ path: self.$route.query.redirect })
        }
        );
    },
  },
};
function onlyCharacters(value) {
  // Regular expression for only characters and spaces
  const regex = /^[A-Za-z\s]+$/;
  return regex.test(value);
}
</script>

<style lang="scss" scoped>
.icon {
  position: relative;
  bottom: -2.5px;
}

a:not([href]):not([tabindex]) {
  color: #2a41e8;
}
</style>
