<template>
  <div>
    <Header v-if="!whithinPopUp">
      <h1>Password reset</h1>
    </Header>
    <div v-if="whithinPopUp" class="margin-top-70"></div>
    <div class="container">
      <div class="row">
        <div :class="whithinPopUp ? 'offset-xl-1 col-xl-10' : 'col-xl-5 offset-xl-3'">
          <div class="login-register-page">
            <!-- Welcome Text -->
            <div class="welcome-text">
              <span>Please fill in your email address in order to reset your password.
                An email with insturctions will be sent to you shortly.</span>
            </div>

            <!-- Form -->
            <form method="post" @submit.prevent="sendResetLink" id="login-form">
              <InputWithIcon>
                <i>
                  <EmailOutline :size="18" class="icon"></EmailOutline>
                </i>
                <input v-model="$v.email.$model" class="input-text with-border" type="email"
                  placeholder="Email Address" />
              </InputWithIcon>
              <p class="text-danger" v-if="$v.email.$dirty && !$v.email.required">
                Email is required.
              </p>
              <p class="text-danger" v-if="$v.email.$dirty && !$v.email.email">
                Please enter a valid email.
              </p>

            </form>

            <!-- Button -->
            <button class="button full-width button-sliding-icon ripple-effect margin-top-10" type="submit"
              form="login-form">
              Send email
              <i>
                <ArrowRightThin :size="18" class="icon"></ArrowRightThin>
              </i>
            </button>


          </div>
        </div>
      </div>


    </div>
    <!-- For footer free space -->
    <div class="margin-top-70"></div>
  </div>
</template>

<script>
import Header from "~/components/base/Header.vue";
import InputWithIcon from "~/components/base/InputWithIcon.vue";
import { mapActions } from "vuex";
import { ACTIONS } from "../../store";
import { required, email } from "vuelidate/lib/validators";


// Icons
import EmailOutline from "icons/EmailOutline.vue";
import LockOutline from "icons/LockOutline.vue";
import ArrowRightThin from "icons/ArrowRight.vue";


export default {
  name: "Password",
  components: {
    Header,
    InputWithIcon,
    EmailOutline,
    LockOutline,
    ArrowRightThin,

  },
  props: {
    whithinPopUp: false
  },
  data() {
    return {
      email: ""
    };
  },
  validations: {
    email: { required, email }
  },
  methods: {
    ...mapActions({
      getResetToken: ACTIONS.CREATE_RESET_TOKEN,
    }),
    async sendEmail(token) {
      console.log(token);
      const message = {
        to: this.email,
        dynamic_template_data: {
          resetUrl: `https://macherusa.com/login?mode=reset&token=${token}&email=${this.email}`
        },
        template_id: "d-038b5d5cdbaf4d4cb08be42415cd8c69"

      };
      try {
        var self = this;
        $nuxt.$root.$loading.start();
        return await this.$mailer
          .sendEmail(message)
          .then(response => {
            if (response.error)
              self.$toast.global.wentWrong();
            else
              self.$toast.global.resetEmailSent();
            $nuxt.$root.$loading.finish();
          })
          .catch((e) => {
            self.$sentry.captureException(e);
            self.$toast.global.wentWrong();
            $nuxt.$root.$loading.finish();
          });
      } catch (e) {
        self.$sentry.captureException(e);
        self.$toast.global.wentWrong();
        this.error = e.response
          ? e.response.data.message
          : "Error while sending message";
      }
    },
    redirectToLogin() {
      this.$emit("switchComponent", "Login");
    },

    sendResetLink() {
      this.$v.$touch();
      if (this.$v.$error) {
        // console.log($v);
        return;
      }

      var self = this;
      $nuxt.$root.$loading.start();
      this.getResetToken({ email: this.email })
        .then((data) => {
          if (data.data && data.data.recordsets.length > 0)
            this.sendEmail(data.data.recordset[0].token);
          //send email
          else
            //toast
            self.$toasted.global.emailDoesNotExist();

        }).catch((ex) => {
          self.$handleExceptionWithSentry(ex);
        })
        .finally(() => $nuxt.$root.$loading.finish());

      //check if email exists
      //emailDoesNotExist
      //resetEmailSent
      try {
        $nuxt.$root.$loading.start();
        const self = this;
      } catch (ex) {
        self.$sentry.captureException(ex);

      }

    },

  }
};
</script>
<style lang="scss" scoped>
.icon {
  position: relative;
  bottom: -2.5px;
}
</style>
