<template>
    <div>
        <div ref="topElement"></div>

        <LogIn @switchComponent="switchComponent" @emailNewUser="welcomeUser" :whithinPopUp="whithinPopUp" :type="type"
            v-if="current.LogIn" />
        <Register @switchComponent="switchComponent" @emailNewUser="welcomeUser" :whithinPopUp="whithinPopUp" :type="type"
            v-if="current.Register" />
        <Password @switchComponent="switchComponent" :whithinPopUp="whithinPopUp" v-if="current.Password" />
        <PasswordReset @switchComponent="switchComponent" :email="email" :token="token" :whithinPopUp="whithinPopUp"
            v-if="current.PasswordReset" />
    </div>
</template>

<script>
import Register from './Register.vue';
import LogIn from './LogIn.vue';
import Password from './Password.vue';
import PasswordReset from './PasswordReset.vue';
import { integer } from 'vuelidate/lib/validators';

const candidateTemplateId = "d-3569026af4ab46158a7b93dab96ec902";
const employerTemplateId = "d-632d48cc444d4eff8e46ada4d16effcc";

export default {
    components: {
        LogIn,
        Register,
        Password,
        PasswordReset
    },
    props: {
        whithinPopUp: {
            type: Boolean,
            default: false
        },
        userType: {
            type: integer,
            deafult: 1
        }
    },
    data() {
        return {
            current: {
                LogIn: true,
                Register: false,
                Password: false,
                PasswordReset: false
            },
            email: "",
            token: "",
            type: 1
        };
    },
    methods: {
        resetComponentState() {
            this.current = {
                LogIn: true,
                Register: false,
                Password: false,
                PasswordReset: false
            };
        },
        switchComponent(selected) {
            this.$refs.topElement.scrollIntoView({ behavior: 'smooth' });
            Object.keys(this.current).forEach(property => this.current[property] = false);
            this.current[selected] = true;
        },
        welcomeUser({ email, name, type }) {
            const message = {
                //to:this.item.email
                to: email,
                dynamic_template_data: {
                    user: {
                        name: name,
                    }
                },
                template_id: type == 1 ? candidateTemplateId : employerTemplateId
            };

            try {
                var self = this;
                $nuxt.$root.$loading.start();
                this.$mailer
                    .sendEmail(message)
                    .then(response => {
                        if (response.error)
                            self.$toast.global.wentWrong();
                        $nuxt.$root.$loading.finish();
                    })
                    .catch(() => {
                        self.$toast.global.wentWrong();
                        $nuxt.$root.$loading.finish();
                    });
            } catch (e) {
                self.$toast.global.wentWrong();

            }
        },
    },

    watch: {
        // Watch for changes in the redirect query parameter
        '$route.query.redirect'(newVal, oldVal) {
            if (newVal !== oldVal) {
                // Reset the component state when the redirect parameter changes
                this.resetComponentState();
            }
        }
    },

    beforeRouteEnter(to, from, next) {
        next(vm => {
            console.log(from);
        });
    },
    mounted() {
        this.type = this.userType;
        const {
            $router: {
                currentRoute: {
                    query: { mode, email, token, userType } = {}
                } = {}
            }
        } = this;
        this.email = email;
        this.token = token;
        if (userType)
            this.type = parseInt(userType);
        if (mode == "reset")
            this.switchComponent("PasswordReset");
        if (mode == "forget")
            this.switchComponent("Password");
    }
};
</script>
