<template>
  <div>
    <Header v-if="!whithinPopUp">
      <h1>Login</h1>
    </Header>
    <div v-if="whithinPopUp" class="margin-top-70"></div>
    <div class="container">
      <div class="row">
        <div :class="whithinPopUp ? 'offset-xl-1 col-xl-10' : 'col-xl-5 offset-xl-3'">
          <div class="login-register-page">
            <!-- Welcome Text -->
            <div class="welcome-text">
              <h3>We're glad to see you again!</h3>
              <span>Don't have an account?
                <a @click.prevent="redirect('Register')">Sign Up!</a>
              </span>
            </div>

            <!-- Form -->
            <form method="post" @submit.prevent="logIn" id="login-form">
              <InputWithIcon>
                <i>
                  <EmailOutline :size="18" class="icon"></EmailOutline>
                </i>
                <input class="input-text with-border" type="email" v-model="$v.email.$model" placeholder="Email Address"
                  required="" />
              </InputWithIcon>
              <p class="text-danger" v-if="$v.email.$dirty && !$v.email.required">
                Email is required.
              </p>
              <InputWithIcon>
                <i>
                  <LockOutline :size="18" class="icon"></LockOutline>
                </i>
                <input class="input-text with-border" type="password" v-model="$v.password.$model"
                  placeholder="Password" />
              </InputWithIcon>
              <p class="text-danger" v-if="$v.password.$dirty && !$v.password.required">
                Password is required.
              </p>
              <a @click.prevent="redirect('Password')" class="forgot-password">Forgot Password?</a>
            </form>

            <!-- Button -->
            <button class="button full-width button-sliding-icon ripple-effect margin-top-10" type="submit"
              form="login-form">
              Log In
              <i>
                <ArrowRightThin :size="18" class="icon"></ArrowRightThin>
              </i>
            </button>

            <!-- Social Login -->
            <div class="social-login-separator"><span>or</span></div>
            <div class="social-login-buttons">
              <button @click="facebookLogIn()" class="facebook-login ripple-effect">
                <i>
                  <Facebook :size="15.2" class="icon"></Facebook>
                </i>
                Login via Facebook
              </button>
              <button id="googleLogin">
              </button>
            </div>
          </div>
        </div>
      </div>


    </div>
    <!-- For footer free space -->
    <div class="margin-top-70"></div>
  </div>
</template>

<script>
var auth2;
import Header from "~/components/base/Header.vue";
import InputWithIcon from "~/components/base/InputWithIcon.vue";
import { required, email } from "vuelidate/lib/validators";
import jwt_decode from "jwt-decode";


// Icons
import EmailOutline from "icons/EmailOutline.vue";
import LockOutline from "icons/LockOutline.vue";
import ArrowRightThin from "icons/ArrowRight.vue";
import Facebook from "icons/Facebook.vue";
import Google from "icons/Google.vue";

export default {
  name: "Login",
  components: {
    Header,
    InputWithIcon,
    EmailOutline,
    LockOutline,
    ArrowRightThin,
    Facebook,
    Google,
  },
  props: {
    whithinPopUp: {
      type: Boolean,
      default: false
    },
    type: {
      type: Number,
      default: 1
    },
  },
  data() {
    return {
      email: "",
      password: "",
      socialLogin: false,
      name: ""
    };
  },
  validations: {
    email: { required },
    password: { required }
  },
  methods: {
    redirect(To) {
      this.$emit("switchComponent", To);
    },
    cleanInputs() {
      // Remove spaces from the email and password fields
      this.email = this.email.trim();
      this.password = this.password.trim();
    },
    logIn(evt) {
      this.cleanInputs();

      if (!this.socialLogin) {
        this.$v.$touch();
        if (this.$v.$error) {
          // console.log($v);
          return;
        };
      }
      this.email = this.email.trim();
      const { email, password, socialLogin, name, type } = this;

      const credentials = {
        email,
        password,
        socialLogin,
        name,
        type
      };

      try {
        $nuxt.$root.$loading.start();
        const self = this;
        this.$auth
          .loginWith("local", {
            data: { user: credentials },
          })
          .then((data) => {
            $nuxt.$root.$loading.finish();
            if (this.$auth.loggedIn) {
              self.$toasted.global.loginsuccessful();

              // Check if the logged-in user is an admin
              const adminUsers = process.env.ADMIN_USERS?.split(",") || []; // Get admin user IDs from env
              if (adminUsers.includes(String(this.$auth.user.userId))) {
                localStorage.setItem("isAdmin", "true"); // Set admin flag
              }

              setTimeout(x => {
                self.$router.push({ path: self.$route.query.redirect });
              }, 1000);

              //send emails to new users from social login
              if (self.$auth.user.newUser)
                self.$emit("emailNewUser", self);

              // self.$router.redirect(self.$route.query.redirect);
            } else {
              self.$toasted.global.incorrectpassword();
            }
          })
          .catch((e) => {
            $nuxt.$root.$loading.finish();
            if (e.response.data.indexOf("password") >= 0)
              self.$toasted.global.incorrectpassword();
            else
              self.$handleExceptionWithSentry(e);
          });
      } catch (e) {
        self.$handleExceptionWithSentry(e);
        self.$router.push("/login");
      }
    },

    loadGoogleLoginLibrary() {
      if (document.getElementById('google-login-script')) {
        this.googleScriptLoaded = true;
        this.initializeGoogleLogin();
        return;
      }

      const script = document.createElement('script');
      script.src = 'https://accounts.google.com/gsi/client';
      script.id = 'google-login-script';
      script.onload = () => {
        this.googleScriptLoaded = true;
        this.initializeGoogleLogin();
      };
      document.head.appendChild(script);
    },
    initializeGoogleLogin() {
      if (!this.googleScriptLoaded) return;

      const clientId = process.env.GoogleAuthClientId;

      google.accounts.id.initialize({
        client_id: clientId,
        callback: this.googleLogIn,
      });

      const button = document.getElementById("googleLogin");
      if (button) {
        const positionInfo = button.getBoundingClientRect();

        google.accounts.id.renderButton(
          button,
          { theme: "outline", size: "large", width: positionInfo.width - 12 }
        );
      }
    },


    googleLogIn(response) {
      const responsePayload = this.decodeJwtResponse(response.credential);
      this.email = responsePayload.email;
      this.name = responsePayload.name;
      this.socialLogin = true;
      this.logIn();
    },
    decodeJwtResponse(token) {
      var decoded = jwt_decode(token);
      return decoded;
    },

    facebookLogIn() {
      var self = this;
      FB.login(
        function (response) {
          if (response.status === "connected") {
            self.loginLocalFacebookUser();
          } else if (response.status === "not_authorized") {
            {
              alert("Failed to log in user with facebook!");
            }
          } else {
            {
              alert("Failed to log in user with facebook!");
            }
          }
        },
        { scope: "public_profile,email" }
      );
    },
    loginLocalFacebookUser() {
      var self = this;
      FB.api(
        "/me",
        "GET",
        { fields: "id,name,birthday,email,about,cover" },
        function (response) {
          self.email = response.email;
          self.name = response.name;
          self.socialLogin = true;
          self.logIn();
        }
      );
    },
    initializeAdminLogin() {
      const isAdmin = localStorage.getItem("isAdmin") === "true"; // Check admin flag in localStorage
      const adminQuery = this.$route.query.admin === "true"; // Check if admin query parameter is present
      const userEmail = this.$route.query.email; // Get user email from query parameter

      if (isAdmin && adminQuery && userEmail) {
        this.email = userEmail; // Set the email for admin login
        this.socialLogin = true; // Set the admin login flag
        this.logIn(); // Trigger the login process
      }
    },
  },
  head() {
    return {
      script: [
        {
          //src: "https://apis.google.com/js/platform.js"
          // src: "https://accounts.google.com/gsi/client",
        },
      ],
    };
  },

  fetch() {
    if (this.$auth.$state.loggedIn)
      this.$router.push({ path: this.$route.query.redirect });
  },
  mounted() {
    // this.$nextTick(() => {

    // Load the SDK asynchronously
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = "//connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");

    var appId = process.env.FacebookAppId;
    window.fbAsyncInit = function () {
      FB.init({
        //test app
        //appId: "786398901808532",
        appId: appId,
        xfbml: true, // parse social plugins on this page
        version: "v3.3", // use version 2.0
      });
    };

    this.loadGoogleLoginLibrary();
    this.$nextTick(() => {
      this.initializeAdminLogin();
    });
    // var clientId = process.env.GoogleAuthClientId;

    // google.accounts.id.initialize({
    //   client_id: clientId,
    //   callback: this.googleLogIn, //method to run after user clicks the Google sign in button
    // });
    // var button = document.getElementById("googleLogin");
    // var positionInfo = button.getBoundingClientRect();

    // google.accounts.id.renderButton(
    //   button,
    //   { theme: "outline", size: "large", width: positionInfo.width - 12 } // customization attributes
    // );
    //google.accounts.id.prompt();
    // });
  },
};
</script>
<style lang="scss" scoped>
.icon {
  position: relative;
  bottom: -2.5px;
}

a:not([href]):not([tabindex]) {
  color: #2a41e8;
}
</style>
