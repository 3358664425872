<template>
  <div>
    <Header v-if="!whithinPopUp">
      <h1>Password reset</h1>
    </Header>
    <div v-if="whithinPopUp" class="margin-top-70"></div>
    <div class="container">
      <div class="row">
        <div :class="whithinPopUp ? 'offset-xl-1 col-xl-10' : 'col-xl-5 offset-xl-3'">
          <div class="login-register-page">
            <!-- Welcome Text -->
            <div class="welcome-text">
              <span>Please generate a new password for resetting your account password.</span>
            </div>

            <!-- Form -->
            <form method="post" @submit.prevent="resetPassword" id="login-form">
              <InputWithIcon title="Should be at least 8 characters long" data-tippy-placement="bottom">
                <i>
                  <LockOutline :size="18" class="icon"></LockOutline>
                </i>
                <input autocomplete="chrome" v-model.trim="$v.password.$model" class="input-text with-border"
                  type="password" name="password" id="password" :secureLength="8" placeholder="Password" />
              </InputWithIcon>

              <p class="text-danger" v-if="$v.password.$dirty && !$v.password.required">
                Password is required.
              </p>
              <p class="text-danger" v-if="$v.password.$dirty && !$v.password.strongPassword">
                Password must be 8+ characters with at least one letter
              </p>

              <InputWithIcon>
                <i>
                  <LockOutline :size="18" class="icon"></LockOutline>
                </i>
                <input autocomplete="off" type="password" class="input-text with-border" name="confirmPassword"
                  id="confirmPassword" v-model.trim="$v.confirmPassword.$model" placeholder="Repeat Password" />
              </InputWithIcon>
              <p class="text-danger" v-if="$v.confirmPassword.$dirty && !$v.password.required">
                Password is required.
              </p>
              <p class="text-danger" v-if="$v.confirmPassword.$dirty && !$v.confirmPassword.sameAs">
                Passwords must be identical
              </p>

            </form>

            <!-- Button -->
            <button class="button full-width button-sliding-icon ripple-effect margin-top-10" type="submit"
              form="login-form">
              Reset Password
              <i>
                <ArrowRightThin :size="18" class="icon"></ArrowRightThin>
              </i>
            </button>


          </div>
        </div>
      </div>


    </div>
    <!-- For footer free space -->
    <div class="margin-top-70"></div>
  </div>
</template>

<script>
import Password from "vue-password-strength-meter";
import { required, sameAs } from "vuelidate/lib/validators";
import Header from "~/components/base/Header.vue";
import InputWithIcon from "~/components/base/InputWithIcon.vue";
import { mapActions } from "vuex";
import { ACTIONS } from "../../store";

// Icons
import LockOutline from "icons/LockOutline.vue";
import ArrowRightThin from "icons/ArrowRight.vue";


export default {
  name: "PasswordReset",
  components: {
    Password,
    Header,
    InputWithIcon,
    LockOutline,
    ArrowRightThin,

  },
  props: {
    whithinPopUp: false,
    email: "",
    token: ""
  },
  data() {
    return {
      password: "",
      confirmPassword: ""
    };
  },
  validations: {
    password: {
      required,
      strongPassword(password) {
        return (
          /[a-z]/.test(password) && // checks for a-z
          /[0-9]/.test(password) && // checks for 0-9
          password.length >= 8
        );
      },
    },
    confirmPassword: {
      required,
      sameAs: sameAs("password"),
    },
  },
  methods: {
    ...mapActions({
      updatePassword: ACTIONS.UPDATE_PASSWORD,
      checkToekn: ACTIONS.CHECK_TOKEN
    }),

    redirectToLogin() {
      this.$emit("switchComponent", "Login");
    },
    async resetPassword() {
      this.$v.$touch();
      if (this.$v.$error) {
        // console.log($v);
        return;
      }
      var self = this;
      try {
        $nuxt.$root.$loading.start();
        this.updatePassword({
          email: this.email,
          newPassword: this.password
        })
          .then((response) => {
            $nuxt.$root.$loading.finish();
            self.$toasted.global.resetPassword();
            setTimeout(function () {
              self.$emit("switchComponent", "LogIn");
            }
              , 2000);
          });
      } catch (e) {
        self.$sentry.captureException(e);

        $nuxt.$root.$loading.finish();
        self.$toasted.global.wentWrong();
      }
    },
  },
  mounted() {
    var self = this;
    $nuxt.$root.$loading.start();
    this.checkToekn({
      token: this.token,
      email: this.email
    })
      .then((response) => {
        $nuxt.$root.$loading.finish();

        if (response.data.recordset.length == 0) {
          self.$toasted.global.tokenInvalid();
          setTimeout(function () {
            self.$emit("switchComponent", "Password");
          }
            , 2000);
        }
      })
  }
}
</script>
<style lang="scss" scoped>
.icon {
  position: relative;
  bottom: -2.5px;
}
</style>
